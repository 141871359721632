// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";

@Component({
  components: {},
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    this.changePage();
  }
  @Watch("$route")
  public changePage() {
    if (this.$route.query.page != undefined) {
      this.model.page.pageCount = Number(this.$route.query.page);
      this.loadListRun();
    } else {
      this.changeSearch();
    }
  }
  @Watch("model.page.viewCount")
  @Watch("model.page.searchOrder")
  @Watch("model.page.searchKeyword")
  public changeSearch() {
    if (this.model.page) {
      this.model.page.pageCount = 1;
      history.pushState("", "", "?page=1");
      this.loadListRun();
    }
  }
  public loadListRun() {
    if (!this.model.loadingListProcess) {
      this.model.loadingList = true;
    }
    this.model.loadingListProcessCount++;
    this.model.freePages = [];
    setTimeout(() => {
      this.model.loadingListProcessCount--;
      if (!this.model.loadingListProcess) {
        this.loadList();
      }
    }, 300);
  }
  public changeClearForm() {
    this.model.page.searchOrder = "new";
    this.model.page.viewCount = 10;
    this.model.page.searchKeyword = "";
    this.model.page.searchDetail = false;
  }
  public async loadList() {
    this.model.freePages = [];
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelPages/Main`,
        {
          page: this.model.page.pageCount,
          search: {
            keyword: this.model.page.searchKeyword,
            viewCount: Number(this.model.page.viewCount),
            order: this.model.page.searchOrder,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        if (!this.model.loadingListProcess) {
          this.model.loadingList = false;
        }
        this.model.page.hitCount = response.hitCount;
        for (const item1 of response.freePages) {
          this.model.freePages.push(
            new model.FreePage(item1.code, item1.title)
          );
        }
      })
      .catch((ex) => {
        throw ex;
        this.model.loadingList = false;
        if (this.model.page) {
          this.model.page.hitCount = 0;
        }
      });
    this.loadingOff();
  }
  public async create() {
    this.$router.push({
      name: "CreatorChannelPageCreate",
      params: {},
    });
  }
  public async deleteItem(code: string, name: string) {
    this.model.deleteCode = code;
    this.model.deleteName = name;
    this.model.deleteConfirmText = "";
    this.$modal.show("deleteConfirm");
  }
  public async deleteMessageYes() {
    this.model.deleteLoading = true;
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/Plans/Main`,
        {
          page: this.model.page.pageCount,
          search: {
            keyword: this.model.page.searchKeyword,
            viewCount: Number(this.model.page.viewCount),
            order: this.model.page.searchOrder,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.loadListRun();
        this.$modal.hide("deleteConfirm");
      })
      .catch((ex) => {
        throw ex;
        this.model.loadingList = false;
        if (this.model.page) {
          this.model.page.hitCount = 0;
        }
      });
    this.loadingOff();
    this.model.deleteLoading = false;
  }
  public async deleteMessageNo() {
    this.$modal.hide("deleteConfirm");
  }
  public sleep(msec: number): Promise<void> {
    return new Promise(function(resolve) {
      setTimeout(function() {
        resolve();
      }, msec);
    });
  }
}
